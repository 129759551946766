import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const handleCookieOkClick = (e) => {
       // sets cookie to signal acceptance of cookie disclaimer and suppress its display
       var expires;
       document.getElementById('cookie-disclaimer').style.display = 'none';
       if (Date()) {
            var date = new Date();
            date.setTime(date.getTime()+(30*24*60*60*1000));  //30 days
            expires = "; expires="+date.toUTCString();
        } else {
            expires="";
        }
       document.cookie = "triarmCookieConsent=ok" + ";" + expires + ";path=/";
       return false;
}
export default function news() {
    return (
        <>
        <div id="triarm-body-container" data-bs-smooth-scroll="true" tabIndex="0" style={{position : 'relative', height: '100%'}}>

        <header>
             <div className="container"><div className="row"><div className="col-12">
             <Navbar page={'news'}/>
            </div></div></div>
        </header>


        <div id="triarm-main-container" className="news">
        <section id="press-releases">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 px-lg-0 mx-auto">
                        <div className="intro-copy">
                            <h2>News</h2>
                        </div>
                    </div>  
                </div>
                <div className="row pr">
                    <div className="col-2 col-lg-2 pr-date ps-lg-4">April 15, 2024</div>
                    <div className="col-10 col-lg-9 pr-abstract pe-lg-4">
                        <a href="/news/press-release/20240415">Lorem ipsum dolor sit amet. Ut voluptatum ipsam qui vero minima in aliquam internos. Nam libero commodi est laborum ipsum vel perspiciatis fuga!</a>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
                </div>
                <div className="row divider">
                    <div className="col col-lg-11 ps-lg-4"><hr></hr></div>
                </div>
            </div>
        </section>
        <section id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <a className="btn btn-triarm-cta mx-auto" href="mailto:sternir-triarm@precisionaq.com" role="button">EMAIL US</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <Footer />

        <nav id="cookie-disclaimer" className="navbar fixed-bottom bg-dark">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10">
                    <p style={{color: 'white'}}>By continuing to use our website, you agree to our use of cookies to give you the best experience, 
                        analyze site analytics, and assist in our marketing efforts. For more information, please review our <a href="/privacy" target="_self">privacy&nbsp;policy</a>.
                    </p>
                    </div>
                    <div className="col-6 col-md-2 mx-auto d-flex flex-column justify-content-center">
                        <a className="btn btn-triarm-ok" href="#" role="button" onClick={handleCookieOkClick}>OK</a>
                    </div>
                </div>
            </div>
        </nav>

        </div>
        </>
    )
}