import React from 'react';

const handleNavClick = (e) => {
    //console.log('nav clicked');
    const triNavBar = document.getElementById('navbarNav');
    //collapse mobile nav menu
    triNavBar.classList.remove('show'); 
    return true;
}

export default function Navbar({ page }) {
    let ignoreRerender = false;
    React.useEffect(() => {
        if (page){
            //console.log('independent page: ' + page);
            let triTargetLink = document.getElementById('nav-' + page);
            if (triTargetLink) {
                triTargetLink.classList.add('active');
            }
        } else {
            //console.log('not an independent page!');
            if (!ignoreRerender) {
                //console.log("page loaded and rendered. location.hash is: " + document.location.hash);
                if (document.location.hash) {
                   // console.log('scrolling');
                    let targetAnchor = document.getElementById(document.location.hash.substring(1))
                    if (targetAnchor) {
                        targetAnchor.scrollIntoView({block: "start", behavior: "smooth"});
                    }
                }
            }
        } 
        return () => { ignoreRerender = true; }
      }, []);
    return (
        <>
        <nav id="triarm-navbar" className="navbar navbar-expand-md">
        <div id="triarm-navbar-ctr">
            <a className="navbar-brand" href="https://triarm.com" target="_self"><img className="navbar-logo" src="/assets/images/triarm-logo.png" alt="TriArm Therapeutics"/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                 <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse" id="navbarNav">
                <ul className="nav navbar-nav" id="triarm-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/#about" onClick={handleNavClick}>About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#our-approach" onClick={handleNavClick}>Approach</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#science" onClick={handleNavClick}>Science</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#how-we-differ" onClick={handleNavClick}>How We Differ</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#pipeline" onClick={handleNavClick}>Pipeline</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#contact-anchor" onClick={handleNavClick}>Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        </nav> 
        </>
    )
}   